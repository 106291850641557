import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

function FeaturedBlocks({ data, applyNegMarginTop }) {
  return (
    <section className="section-stripe-bg">
      <div
        className={`${applyNegMarginTop ? '-mt-10 pb-12' : 'py-12'} px-4 md:px-6 container grid gap-3 mx-auto md:grid-cols-${
          data.length ? Math.round(data.length / 2) : 2
        } lg:grid-cols-${data.length ? data.length : 4} `}
      >
        {data.map((block, i) => (
          <div key={i} className="flex flex-col rounded-lg shadow overflow-hidden">
            <div className="flex-shrink-0">
              <Img
                fluid={block && block.image.childImageSharp.fluid}
                alt={block && block.title}
                className="h-48 w-full object-cover"
              />
            </div>

            <div className="flex-1 bg-white py-6 px-4 flex flex-col justify-between">
              <div className="flex-1">
                <h3 className="font-display font-semibold text-lg">{block && block.title}</h3>
                <p className="text-gray-700">{block && block.description}</p>
                
                </div> 
              <div className="mt-6 flex items-center"><Link to={block && block.buttonlink} title={block && block.buttontext} className="button-active-bhvr text-white cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none font-semibold px-4 py-2 rounded-full font-display block">{block && block.buttontext}</Link></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FeaturedBlocks;

//because the # of cols can vary, adding classes list here to not be purged during compile
// grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 grid-cols-2 sm:grid-cols-2
// md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4
// grid-cols-5 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5
