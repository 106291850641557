import React, { useState, useEffect, useCallback } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import AdvancedSettings from "../settings/advanced_settings.json";
import StarsRatingComponent from "./StarsRatingsComponent";

function HomeSlide({ items, applyPosPaddingBottom, headerTransparent }) {
  return (
    <section>
      <div className="h-auto bg-cover relative bg-black">
        <Img
          fluid={items && items[0].image.childImageSharp.fluid}
          alt={items && items[0].title}
          className="carousel-image absolute top-0 h-full"
          style={{ opacity: "1", transform: "none" }}
        />

        <div
          className={`${
            applyPosPaddingBottom ? "pb-40" : ""
          } container py-32 carousel-content-container relative mx-auto px-4 md:px-6`}
          style={{ paddingTop: headerTransparent ? "162px" : "128px" }}
        >
          <div className="flex">
            <div className="w-full md:w-2/3">
              <h2
                className="font-bold text-4xl leading-tight mb-1 text-white font-display"
                style={{ textShadow: "rgba(0, 0, 0, 0.5) 0px 3px 10px" }}
              >
                {items && items[0].title}
              </h2>
              <span
                className="text-lg md:text-xl text-white"
                style={{ textShadow: "rgba(0, 0, 0, 0.5) 0px 3px 10px" }}
              >
                {items && items[0].description}
              </span>
              <div className="mt-4">
                <Link
                  to={`${items && items[0].buttonlink}`}
                  className="button-active-bhvr inline-block cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none font-semibold px-6 py-4 rounded-full text-white font-display"
                >
                  {items && items[0].buttontext}
                </Link>
              </div>

              <StarsRatingComponent
                className={"mt-4"}
                reviewavg={AdvancedSettings.reviewavg}
                reviewscount={AdvancedSettings.reviewscount}
                enabled={AdvancedSettings.ratingstarsdisplayenabled}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSlide;
